.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: var(--sidebar-bg);
  border-right: 2px solid #eeeeee;
}

.modal-action-container {
  background: #ffffff;
  padding: 15px;

  img {
    width: 20px;
    aspect-ratio: 1/1;
  }
}

.collapse-toggle-container {
  display: flex;
  flex-direction: column;
  // background-color: #ffffff;
  padding: 20px 0;
  // padding: 5px;
}

.collapse-toggle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 28px;
    height: auto;
  }
}

.logo-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .small-logo {
    height: 38px;
    aspect-ratio: 1/1;
  }

  .large-logo {
    height: 38px;
    margin: 0 10px;
  }
}

.inactive:hover {
  background-color: rgb(234, 234, 234) !important;
}

.navigation-wrapper {
  // padding: 30px 0;
  padding: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 100px);

  &::-webkit-scrollbar {
    width: 0;
  }

  .navigation {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .main {
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 10px 15px;

      img {
        width: 24px;
        height: 24px;
        align-self: center;
      }

      p {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .submenu {
      display: flex;
      flex-direction: column;

      .menu {
        display: flex;
        gap: 15px;
        padding: 10px 20px;

        img {
          width: 18px;
          height: 21px;
          aspect-ratio: 1/1;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
}

.expandIcon {
  // height: auto;
  // width: 500px;
}

.active {
  background: var(--primary);
  color: #ffffff;
}
