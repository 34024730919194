.container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.49);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;

  .modal {
    border-radius: 10px;
    background: #ffffff;
    z-index: 9999999;
  }
}
