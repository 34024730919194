/*Light theme*/
:root {
  --background-color: #f3f3f3;
  --sidebar-bg: #fafafa;
  --primary: #007abe;
}

/*Dark theme*/
[data-theme="dark"] {
  --background-color: #f3f3f3;
}
