.container {
	display: flex;
	min-width: 100%;
	min-height: 100%;
	justify-content: center;
	align-items: center;
}
.wrapper {
	display: flex;
	width: 200px;
	height: 200px;
	align-items: center;
	justify-content: center;
}
.circular-progressbar {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #ff3d00;
	position: relative;
}
.circular-progressbar:before,
.circular-progressbar:after {
	content: "";
	position: absolute;
	border-radius: 50%;
	inset: 0;
	background: #000000;
	transform: rotate(0deg) translate(30px);
	animation: rotate 1s ease infinite;
}
.circular-progressbar:after {
	animation-delay: 0.5s;
}
@keyframes rotate {
	100% {
		transform: rotate(360deg) translate(30px);
	}
}
