.success-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    gap: 1.5rem;
  
    img {
      height: 120px;
      width: 120px;
      object-fit: cover;
    }
  
    .heading {
      font-size: 18px;
      color: var(--primary);
      font-weight: bold;
      text-align: center;
    }
  
    .message {
      font-size: 16px;
      padding: 10px 20px;
      max-width: 400px;
      text-align: center;
    }
  
    .btn {
      border-radius: 5px;
      border: 1px solid transparent;
      transition: .2s;
    }

    .negative {
      background-color: #efefef !important;
      color: #000000;
      border-color:#dedede;
      &:hover {
        background-color: #d1d1d1 !important;
      }
    }

    .btn_container{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap : 10px;
    }
  }
  